
import GenericMixin from '@/lib/mixins/GenericMixin';
import TasqFeedbackMixin from '@/lib/mixins/TasqFeedbackMixin';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { getComponent, sleep, getConfigEnv } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    TasqWellStatus: () => getComponent('tasqs/TasqWellStatus'),
    TasqWellAction: () => getComponent('tasqs/TasqWellAction'),
    TasqWellActionFeedback: () => getComponent('tasqs/TasqWellActionFeedback'),
    TasqWellFinalStepFeedback: () => getComponent('tasqs/TasqWellFinalStepFeedback'),
    TasqSetpointFeedback: () => getComponent('tasqs/TasqSetpointFeedback'),
    DefermentLabeling: () => getComponent('tasqs/DefermentLabeling'),
    TasqWellDetails: () => getComponent('tasqs/TasqWellDetails'),
  },
})
export default class TasqFeedbackV5 extends mixins(TasqFeedbackMixin, GenericMixin) {
  DATA_ENTRY_TAB = 'Data entry';

  HISTORY_TAB = 'Details';

  activeTab = this.DATA_ENTRY_TAB;

  defermentValue: any = -1

  defermentLabelingModal = false;

  hoverGoodCatch = false;

  hoverWrongCatch = false;

  resizeLoading = false;

  resizeTrigger: any = null;

  collapseWellStatus = false;

  hideWellActionSection = false;

  get operatorName() {
    return getConfigEnv('OPERATOR_LOWERCASED');
  }

  get productionUnit() {
    const productionType = getConfigEnv('PRODUCTION_TYPE');
    if (this.operatorName === 'petronascanada') {
      return 'e3m3';
    }
    if (productionType === 'oil') {
      return 'bbl';
    } if (productionType === 'boe') {
      return 'BOE';
    }
     return 'mmcf';
  }

  get showFooterSection() {
    return (
      this.feedbackStepNo !== 3
      && this.activeTab === this.DATA_ENTRY_TAB
      && !(this.tasq && this.tasq.completed != null && this.tasq.completed)
    );
  }

  resizeWindow() {
    this.resizeLoading = true;
    this.$nextTick(() => {
      this.resizeLoading = false;
    });
  }

  toogleWellActions() {
    this.hideWellActionSection = true;
    this.$nextTick(() => {
      this.hideWellActionSection = false;
    });
  }

  retrainTasq() {
    if (this.tasq && !this.isBatchResponding) {
      this.defermentLabelingModal = true;
    }
  }

  async created() {
    this.resizeTrigger = window.addEventListener('resize', this.resizeWindow);
    // const { tab } = this.$route.query;

    if (this.tasq) {
      if (this.tasq.formsResponse && this.tasq.formsResponse.length && this.tasq.isManuallyAdded) {
        tasqFeedbackModule.setSeletedForms(this.tasq.formsResponse);
        tasqFeedbackModule.setStepper(2);
      }

      if (this.tasq.snoozed && !this.tasq.isSnoozedForToday) {
        if (
          new Date().getTime() < new Date(this.tasq.snoozed.WaitStartDate).getTime()
          || new Date().getTime() <= new Date(this.tasq.snoozed.UntilDate).getTime()
        ) {
          this.collapseWellStatus = true;
        }
      }

      if (this.tasq.isGoodCatch) {
        await this.getGoodCatchCalulations();
      }
    }
  }

  get isBatchResponding() {
    return tasqsListModule.isBatchResponding;
  }

  get isSetpointTasq() {
    return this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE');
  }

  async resetDefermentPage() {
    this.defermentLabelingModal = false;
    await sleep(0.1);
    this.defermentLabelingModal = true;
  }

  backHandler() {
    tasqFeedbackModule.setStepper(1);
  }

  async nextHandler() {
    if (this.feedbackStepNo === 1) {
      this.handleFormResponse({ markAsComplete: false });
    } else if (this.feedbackStepNo === 2) {
      // @ts-ignore
      const isValidForm = await this.$refs.tasqWellActionFeedback.submitInput();
      if (isValidForm) {
        this.handleFormResponse({ markAsComplete: true });
      }
    }
  }

  async submitDeferment() {
    this.defermentLabelingModal = false;
    this.$tasqAlert({
      title: 'Success',
      message: 'Retrain successfully.',
      type: 'success',
    });
    this.getWellHistory();
  }

  async removeTarget(message) {
    this.defermentLabelingModal = false;
    this.$tasqAlert({
      title: 'Success',
      message,
      type: 'success',
    });
    this.getWellHistory();
  }

  async goodCatchSubmit(isGoodCatch) {
    await tasqsListModule.updateTasqGoodCatch({ predictionID: this.tasq.id, isGoodCatch });
    await this.getGoodCatchCalulations();
  }

  async getGoodCatchCalulations() {
    const defermentValue = await tasqsListModule.getGoodCatchDefermentByTime();
    this.defermentValue = defermentValue ? Math.round(defermentValue) : 0;
  }
}
